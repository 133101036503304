//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getcode } from '@/api/organizational'
import ElImageViewer from "element-ui/packages/image/src/image-viewer";


//获取省市区三级联动数据接口
import {
  _getProvinceList,
  _getProvinceCityList,
  _getCityCountyList,
  _getSubmitUser,
  _getgetinfo,
  _download,
  _getUniqueName,
} from '@/api/province'
export default {
  data() {
    return {
      srcList: [
          'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
          'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
        ],
      dialogVisible: false,
      textbtn: '审核',
      ssss: '',
      dataAdd: [],
      formInline: {
        name: '', //主体名称
        organType: '', //主体机构类型
        address: '', //详细地址
        contactName: '', //联系人姓名
        contactPhone: '', //联系人手机号
        email: '', //邮箱
        code: '', //社会信用代码
        regionCode: '', //市
        provinceid: '', //活动区域
        cityid: '', //省
        businessLicenseOssIds: [], //营业执照回显数组
        securityqualificationOssIds: [], //安防回显数组
      },
      suffix: '',
      ossIds: [], //提交的时候图片的ossid数组
      cityCountylist: [],
      provinceCitylist: [],
      provinceList: [],
      imgsList: [], // 文件上传列表
      newimgsList: [], // 文件上传列表
      dialogImageUrl: '', //查看大图
      disab: false,
      roletype: '', //角色
      rules: {
        name: [
          { required: true, message: '主体名称不能为空', trigger: 'blur' },
        ],
        organType: [
          {
            required: true,
            message: '主体机构类型为不能空',
            trigger: 'change',
          },
        ],
        address: [
          { required: true, message: '详细地址为不能空', trigger: 'blur' },
        ],
        contactName: [
          { required: true, message: '联系人姓名为不能空', trigger: 'blur' },
        ],
        contactPhone: [
          { required: true, message: '联系人电话为不能空', trigger: 'blur' },
          {
            required: true,
            pattern: /^1[123456789]\d{9}$/,
            message: '请输入正确的联系方式',
          },
        ],
        email: [
          { required: true, message: '邮箱为不能空', trigger: 'blur' },
          {
            required: true,
            pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
            message: '请输入正确的邮箱',
          },
        ],
        code: [
          { required: true, message: '社会信用码为不能空', trigger: 'blur' },
          {
            required: true,
            pattern: /[1-9A-GY]{1}[1239]{1}[1-5]{1}[0-9]{5}[0-9A-Z]{10}/,
            message: '请输入正确的社会信用码',
          },
        ],
        cityid: [{ required: true, message: '区不能为空', trigger: 'change' }],
        regionCode: [
          { required: true, message: '市不能为空', trigger: 'change' },
        ],
        provinceid: [
          { required: true, message: '省份不能为空', trigger: 'change' },
        ],
      },
    }
  },
  components : {
    ElImageViewer 
  },
  created() {
    this.roletype = JSON.parse(localStorage.getItem('roleType'))
    this.getcode()
    //获取省的数据
    _getProvinceList({}).then(res => {
      this.provinceList = res.data
    })

    // 回显数据
    _getgetinfo({}).then(res => {
      if (res.code == '1') {
        if (res.data == null || res.data.checkStatus == null) {
          this.disab = false
          this.textbtn = '审核'
        }
        // 审核中
        else if (res.data.checkStatus == '0') {
          this.formInline = Object.assign(res.data)
          res.data.businessLicenseOssIds.forEach(item => {
            this.imgsList.push({
              url:
                '/ananops/api/v1/pub/file/download?ossId=' +
                `${item.ossId}` +
                '&type=pc',
            })
          })
          res.data.securityqualificationOssIds.forEach(item => {
            this.newimgsList.push({
              url:
                '/ananops/api/v1/pub/file/download?ossId=' +
                `${item.ossId}` +
                '&type=pc',
            })
          })
          this.formInline.organType = res.data.organType
          this.disab = true
          this.textbtn = '审核中'
          _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
            res => {
              this.provinceCitylist = res.data
            },
          )
          _getCityCountyList({ parent_id: this.formInline.cityid }).then(
            res => {
              this.cityCountylist = res.data
            },
          )
        } else if (res.data.checkStatus == '1') {
          this.formInline = Object.assign(res.data)
          res.data.businessLicenseOssIds.forEach(item => {
            this.imgsList.push({
              url:
                '/ananops/api/v1/pub/file/download?ossId=' +
                `${item.ossId}` +
                '&type=pc',
            })
          })
          res.data.securityqualificationOssIds.forEach(item => {
            this.newimgsList.push({
              url:
                '/ananops/api/v1/pub/file/download?ossId=' +
                `${item.ossId}` +
                '&type=pc',
            })
          })
          this.disab = true
          this.textbtn = '已审核'
          _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
            res => {
              this.provinceCitylist = res.data
            },
          )
          _getCityCountyList({ parent_id: this.formInline.cityid }).then(
            res => {
              this.cityCountylist = res.data
            },
          )
        } else if (res.data.checkStatus == '2') {
          this.formInline = Object.assign(res.data)
          res.data.businessLicenseOssIds.forEach(item => {
            this.imgsList.push({
              url:
                '/ananops/api/v1/pub/file/download?ossId=' +
                `${item.ossId}` +
                '&type=pc',
            })
          })

          res.data.securityqualificationOssIds.forEach(item => {
            this.newimgsList.push({
              url:
                '/ananops/api/v1/pub/file/download?ossId=' +
                `${item.ossId}` +
                '&type=pc',
            })
          })
          let businessLicenseOssIds = [];
          this.formInline.businessLicenseOssIds.forEach(item => {
            businessLicenseOssIds.push(item.ossId)
          })
          this.formInline.businessLicenseOssIds = businessLicenseOssIds;
          let securityqualificationOssIds = [];
          this.formInline.securityqualificationOssIds.forEach(item => {
            securityqualificationOssIds.push(item.ossId)
          })
          this.formInline.securityqualificationOssIds = securityqualificationOssIds;

          this.disab = false
          this.textbtn = '审核'
          _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
            res => {
              this.provinceCitylist = res.data
            },
          )
          _getCityCountyList({ parent_id: this.formInline.cityid }).then(
            res => {
              this.cityCountylist = res.data
            },
          )
        }
        console.log(this.formInline,this)
      }
    })
  },
  methods: {
    changeProvince(e) {
      //获取市的数据
      _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
        res => {
          this.provinceCitylist = res.data
        },
      )
    },
    changeProvinceCity(e) {
      //获取区的数据
      _getCityCountyList({ parent_id: this.formInline.cityid }).then(res => {
        this.cityCountylist = res.data
      })
    },
    // 审核按钮
    submitUser() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          _getSubmitUser(this.formInline).then(res => {
            if (res.code == 1) {
              this.disab = true
              this.textbtn = '审核中'
              this.$message('提交审核成功,请您耐心等待')
            }
          })
        }
      })
    },
    // 失焦事件
    blurs() {
      _getUniqueName({ name: this.formInline.name }).then(res => {
        if (res.code == '1') {
          this.suffix = 'el-icon-success'
        } else {
          this.suffix = 'el-icon-error'
        }
      })
    },
    getcode() {
      let from = {
        para: 'jglx',
      }
      _getcode(from).then(res => {
        this.dataAdd = res.data
      })
    },
    // 点击大图显示
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 关闭查看器
    closeViewer() {
      this.dialogVisible = false
    },
    // 营业执照成功返回的数据
    successFile(response, file, fileList) {
      this.formInline.businessLicenseOssIds.push(response.data.ossId);
    },
    // 营业执照图片上传接口
    changeFile(file, fileList) {},
    // 营业执照移除图片
    removeFile(file, fileList) {
      // console.log(file.url.split('=')[1].split('&')[0])
      let item = file.response ? file.response.data.ossId : file.url.split('=')[1].split('&')[0];
      this.formInline.businessLicenseOssIds.forEach((items,index ) => {
        if(item == items){
          this.formInline.businessLicenseOssIds.splice(index,1)
        }
      });
      // this.formInline.businessLicenseOssIds.splice(item)
      console.log(this.formInline.businessLicenseOssIds)
    },
    // 安防成功返回的数据
    newsuccessFile(response, file, fileList) {
      this.formInline.securityqualificationOssIds.push(response.data.ossId);
      console.log(1111)
    },
    // 安防图片上传接口
    newchangeFile(file, fileList) {},
    // 安防移除图片
    newremoveFile(file, fileList) {
      let item = file.response ? file.response.data.ossId : file.url.split('=')[1].split('&')[0];
       this.formInline.securityqualificationOssIds.forEach((items,index ) => {
        if(item == items){
          this.formInline.securityqualificationOssIds.splice(index,1)
        }
      });
      
      // this.formInline.securityqualificationOssIds.splice(item, 1)
      console.log(this.formInline.securityqualificationOssIds)
    },
  },
}
